import { Button, Container, Link, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AppLogo } from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { useWebsiteContext } from '../../components';
import { HeaderContainer } from '../../components/navbar/HeaderContainer';

type PpRestrictedHeader = {
  href?: string;
} & PpWC;

const Styledbanner = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.websiteBrand.greyscale.white,
  borderBottom: `1px solid ${theme.palette.websiteBrand.greyscale.grey}`,
  lineHeight: 1,
  padding: theme.spacing(1.5, 3),
  textAlign: 'center',
  width: '100%',
}));

export function RestrictedHeader({ children, href }: PpRestrictedHeader): React.ReactElement {
  const theme = useTheme();
  const { isScrolled } = useWebsiteContext();

  return (
    <HeaderContainer
      backgroundColor="transparent"
      banner={
        <Styledbanner>
          <Typography variant="buttonS">{children}</Typography>{' '}
          <Link color="text.primary" href={href} variant="buttonS">
            Take 2 minutes to learn more.
          </Link>
        </Styledbanner>
      }
      isScrolled={isScrolled}
      position="sticky"
    >
      <Container disableGutters component="nav" maxWidth="lg" sx={{ padding: theme.spacing(2) }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
          <Link href="/uk">
            <AppLogo />
          </Link>
          <Button color="primary" href="/uk" size="small" variant="contained">
            Home
          </Button>
        </Stack>
      </Container>
    </HeaderContainer>
  );
}
