import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { UkPrivacyPageQuery } from '../../../generated/types';
import { HomepageUkTemplate } from '../../components/templates/HomepageUkTemplate';
import { PrivacyPolicyContent } from '../../modules/legal/PrivacyPolicyContent';

type PpUkPrivacyPage = PageProps<UkPrivacyPageQuery>;

function PrivacyPage({ data: { content } }: PpUkPrivacyPage): React.ReactElement {
  return (
    <HomepageUkTemplate seo={content?.frontmatter?.seo}>
      <PrivacyPolicyContent content={content} sx={{ paddingY: 4 }} />
    </HomepageUkTemplate>
  );
}

export const query = graphql`
  query UkPrivacyPage {
    content: markdownRemark(frontmatter: { layout: { eq: "privacy" }, locale: { eq: "en" } }) {
      rawMarkdownBody
      frontmatter {
        locale
        title
        privacyDescription
        date(formatString: "DD MM YYYY")
        seo {
          title
          description
          image
        }
      }
    }
  }
`;

export default PrivacyPage;
