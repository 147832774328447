import type { ReactElement } from 'react';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { graphql, useStaticQuery } from 'gatsby';
import type {
  HomepageUkTemplateQuery,
  MarkdownRemarkFrontmatterHeaderMenuHeaderLinks,
} from '../../../generated/types';
import { RestrictedHeader } from '../../modules/restricted/RestrictedHeader';
import { BaseWrapper } from '../layout/BaseWrapper';
import { Footer } from '../layout/Footer';
import type { PpSeo } from '../layout/Seo';

type PpHomepageUkTemplate = {
  seo?: PpSeo | null;
} & PpWC;

const query = graphql`
  query HomepageUkTemplate {
    ukRiskWarning: markdownRemark(frontmatter: { layout: { eq: "UkRiskWarning" } }) {
      frontmatter {
        banner
      }
    }
    homepageUk: markdownRemark(frontmatter: { layout: { eq: "UkHomepage" } }) {
      frontmatter {
        legalCompanyName
        headerLinks {
          headerLabel
          headerURL
          sublinks {
            id
            sublinkLabel
            sublinkUrl
          }
        }
      }
    }
  }
`;

export function HomepageUkTemplate({ children, seo }: PpHomepageUkTemplate): ReactElement {
  const { homepageUk, ukRiskWarning } = useStaticQuery<HomepageUkTemplateQuery>(query);

  return (
    <BaseWrapper
      FooterSlot={
        <Footer
          footerMenu={
            homepageUk?.frontmatter?.headerLinks as MarkdownRemarkFrontmatterHeaderMenuHeaderLinks[]
          }
          legalCompanyName={homepageUk?.frontmatter?.legalCompanyName}
        />
      }
      HeaderSlot={
        <RestrictedHeader href="/uk/risk-warning">
          {ukRiskWarning?.frontmatter?.banner}
        </RestrictedHeader>
      }
      seoProps={seo}
    >
      {children}
    </BaseWrapper>
  );
}
