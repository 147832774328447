import type { ReactElement } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import type { PrivacyPageQuery } from '../../../generated/types';
import { LegalContainer } from './LegalContainer';

type PpPrivacyPolicyContent = {
  content: PrivacyPageQuery['content'];
  sx?: SxProps<Theme>;
};

export function PrivacyPolicyContent({ content, sx }: PpPrivacyPolicyContent): ReactElement {
  return (
    <LegalContainer sx={sx}>
      <Typography component="h1" variant="subHeadingL">
        {content?.frontmatter?.title}
      </Typography>
      {content?.rawMarkdownBody && <Markdown>{content?.rawMarkdownBody}</Markdown>}
      <Typography component="span" variant="caption">
        Last updated on {content?.frontmatter?.date}
      </Typography>
    </LegalContainer>
  );
}
